//#region Rely
import React, { useState } from "react";
import classNames from "classnames";
//#endregion

//#region component
import MainFrame from "../components/main-frame";
import MainMenu from "../components/main-menu";
import ProjectCase from "../components/widgets/projectCase";
import ProjectTitle from "../components/widgets/projectTitle";
import FunctionCase from "../components/widgets/functionCase";
//#endregion

//#region option
import {
    contactNav,
} from "../staticConfMange/navMange.js"
import {
    buildCaseList,
    projectCaseList,
} from "../staticConfMange/projectMessage.js"
import { lightweightList, vrList } from '../staticConfMange/functionMessage'
//#endregion

//#region cssRely
import {
    pageHeader,
    mainMenu,
} from "./index.module.css"
import {
    partners,
    partner,
    partnerTieJian,
    partnerChentou,
    partnerChentouTech,
    partnerZuohai,
    partnerFuda,
    partnerFuzhou,
    partnerFuzhouchentou,
    partnerHaixia,
    partnerHongkon,
    partnerJiangxi,
    partnerJianzhu,
    // partnerTiejan,
    partnerTongji,
    partnerTsinghua,
    partnerTumu,
    partnerXinjiang,
    partnerZhongjiao,
    partnerZhongye,
    partnerChenxiang,
    partnerZhongnan, partnerZhongtie
} from './index.partners.module.css';
import {

    optionContainer
} from "./product.module.css"


import {
    main,
    aboutus,
    line,
    text,
    textRight,
    textLeft,
    target,
    why,
    certificate,
    certificateMain,
    zyq,
    zyqName,
    zyqRight,
    people,
    top,
    bottom,
    peopleImg,
    peopleRight,
    peopleName,
    peoplePy,
    news,
    newImg,
    newRight,
    newLeft
} from './about.module.css'
//sass
//7/7sass的热重载会有问题，所以暂时还是不使用
// import("./project.scss");
//#endregion
/**
 * 
 * @returns {JSX.Element}
 */
export default function Porject({ path }) {
    const [active, setActive] = useState(0);
    return (
        <MainFrame id="project-container">
            <header className={pageHeader}>
                <MainMenu className={mainMenu} navPorps={contactNav} active={active} path={path} setActive={setActive} />
                <img alt="" style={{ width: '100%' }} src={'/about/about-bg.jpg'} />
            </header>
            {/* 带有.main的都是一个frame，后期看看Gatsby是否有路由插槽这样的东西，没有就这样子算了。有就拆分成组件，然后再显示 */}
            <div className={main}>
                <section className={optionContainer} id='a'>
                    <div className={aboutus}>
                        <h4>公司简介</h4>
                        <div className={line}></div>
                        <p className={target}>以行业需求为目标、以用户体验为宗旨、以科技发展为驱动</p>
                        <div className={text}>
                            <div className={textLeft}>
                                <h5>关于青云季</h5>
                                <p>福建青云季建筑科技有限公司（简称青云季建科）是一家专注于工程项目全过程BIM咨询管理的科技型公司，针对高速公路、城市交通枢纽、市政管廊、城市轨道交通、精装修、古建筑等板块，提供全面的BIM一体化解决方案，从根本上解决工程项目规划、设计、施工、维护管理各阶段的信息断层问题，实现全过程的工程信息化管理。</p>
                                <p>公司团队人员先后经历内昆、渝怀、青藏铁路，美国奥克兰海湾大桥，四川都安高速特长隧道，郑州、厦门、福州、上海城市轨道交通，重庆沙坪坝交通枢纽、尼日利亚Lagos高铁车站等地标性BIM咨询项目，具有各类丰富的BIM咨询经验。</p>
                                <p>青云季自成立以来，已在参数化建模、智能加工、桥梁智能模板管理、智能物料管理、信息化梁厂管理等方向取得积极成果，并在GIS、物联网、互联网、智慧测量、VR、AR、AI、3D打印等BIM+领域做进一步深入研究。</p>
                            </div>
                            <div className={textRight}><img src="/about/about-us.png" alt="" /></div>
                        </div>
                        <div className={why}>为什么选择我们</div>
                        <div className={certificate}>
                            <div className={certificateMain}>
                                <img src="/about/about-certificate-1.png" alt="" />
                                <p>多年BIM应用及研发经验</p>
                            </div>
                            <div className={certificateMain}>
                                <img src="/about/about-certificate-2.png" alt="" />
                                <p>多年BIM应用及研发经验</p>
                            </div>
                            <div style={{ width: '100%' }} className={certificateMain}>
                                <img style={{ width: '100%' }} src="/about/about-certificate-3.png" alt="" />
                                <p>多年BIM应用及研发经验</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="b" style={{ padding: '60px 10%' }} className={optionContainer}>
                    <div className={aboutus}>
                        <h4>强力的核心团队</h4>
                        <div className={line}></div>
                        <p className={target}>以行业需求为目标、以用户体验为宗旨、以科技发展为驱动</p>
                        <div className={zyq}>
                            <img style={{ width: '42%' }} src="/about/zyq.png" alt="" />
                            <div className={zyqRight}>
                                <div className={zyqName}>张岩清 <span>青云季董事长</span></div>
                                <ul>
                                    <li>青云季董事长</li>
                                    <li>福建省勘察设计协会委员</li>
                                    <li>浙江省建筑信息（BIM）科委会委员</li>
                                    <li>BIM战略规划师</li>
                                    <li>高级工程师</li>
                                </ul>
                                <img style={{ width: '70%', marginBottom: '1rem' }} src="/about/zyqlogo.png" alt="" />
                                <p>长期从事土木工程信息技术方面的教学和科研，我国BIM技术最早研究者和推行者之一。</p>
                                <p>主持完成了多个领域项目包含市政公共设施、民用建筑的BIM技术应用及执行，在土木工程CAD、BIM、建筑生命期管理、建设领域信息化、数字减灾及智能决策技术方面开展了多方面的研究，具有较深的学术造诣，其中在BIM理论技术研究、标准编制、软件开发及应用方面具有丰富的工作积累。</p>
                            </div>
                        </div>
                        <div className={people}>
                            <div>
                                <div className={top}>
                                    <div className={peopleImg}>
                                        <img src="/about/wy.png" alt="" />
                                    </div>
                                    <div className={peopleRight}>
                                        <div className={peopleName}>王 莹</div>
                                        <div className={peoplePy}>（Ying Wang）</div>
                                        <ul>
                                            <li>青云季总工程师</li>
                                            <li>副研究员，博士，硕士生导师</li>
                                            <li>福建省引进高层次人才（境外B类）</li>
                                            <li>美国加州注册工程师</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={bottom}>
                                    <ul>
                                        <li>2014年毕业于美国普渡大学博士学位</li>
                                        <li></li>
                                        <li>2022 年 1 月—至今 副研究员 福州大学土木工程学院</li>
                                        <li>2018 年 9 月—2021 年 12 月 助理研究员 福州大学土木工程学院</li>
                                        <li>2014 年 12 月—2018 年 6 月 高级工程师 WSP USA 纽约办公室</li>
                                        <li>2011 年 5 月—2014 年 5 月 研究助理 美国地震工程模拟网络（NEES）</li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <div className={top}>
                                    <div className={peopleImg}>
                                        <img src="/about/lp.png" alt="" />
                                    </div>
                                    <div className={peopleRight}>
                                        <div className={peopleName}>李 鹏</div>
                                        <div className={peoplePy}>（Peng Li）</div>
                                        <ul>
                                            <li>青云季版块总监</li>
                                            <li>Engineering Analysis with Boundary Elements独立审稿人 </li>
                                            <li>地下空间与工程学报，独立审稿人</li>
                                            <li>中国城市地下综合管廊产业联盟专家委员会专家 </li>
                                            <li>四川省土木建筑学会第 12 届监事会监事 </li>
                                            <li>中国岩石力学与工程学会地下空间分会第三届理事会理事 </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={bottom}>
                                    <ul>
                                        <li>2001.09-2005.07 同济大学土木工程学院本科</li>
                                        <li>2005.09-2008.04 同济大学土木工程学院隧道及地下建筑系硕士研究生 </li>
                                        <li>2008.11-2012.10 奥地利格拉茨技术大学（Graz University of Technology）土木工程学院博士研究生</li>
                                        <li>2012.10 获工学博士学位，毕业论文“非饱和多孔介质波及其边界单元法”</li>
                                        <li>2009.10 第七届快速边界元法及其工业应用，奥地利 Hirschegg </li>
                                        <li>2017.09 企业首席质量官（CQO）任职培训，成都</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <section id="c" style={{ padding: '120px 16%' }} className={optionContainer}>
                    <div className={aboutus}>
                        <h4>主要合作伙伴</h4>
                        <div className={line}></div>
                        <p className={target}>以行业需求为目标、以用户体验为宗旨、以科技发展为驱动</p>
                        <table className={partners}>
                            <tbody>
                                <tr>
                                    <td className={classNames(partner, partnerTieJian)}></td>
                                    <td className={classNames(partner, partnerZhongtie)}></td>
                                    <td className={classNames(partner, partnerTumu)}></td>
                                    <td className={classNames(partner, partnerZhongye)}></td>
                                    <td className={classNames(partner, partnerZhongjiao)}></td>
                                </tr>
                                <tr>
                                    <td className={classNames(partner, partnerJianzhu)}></td>
                                    <td className={classNames(partner, partnerFuzhou)}></td>
                                    <td className={classNames(partner, partnerXinjiang)}></td>
                                    <td className={classNames(partner, partnerHaixia)}></td>
                                    <td className={classNames(partner, partnerFuzhouchentou)}></td>
                                </tr>
                                <tr>
                                    <td className={classNames(partner, partnerZuohai)}></td>
                                    <td className={classNames(partner, partnerChenxiang)}></td>
                                    <td className={classNames(partner, partnerChentou)}></td>
                                    <td className={classNames(partner, partnerChentouTech)}></td>
                                    <td className={classNames(partner, partnerJiangxi)}></td>
                                </tr>
                                <tr>
                                    <td className={classNames(partner, partnerTsinghua)}></td>
                                    <td className={classNames(partner, partnerTongji)}></td>
                                    <td className={classNames(partner, partnerZhongnan)}></td>
                                    <td className={classNames(partner, partnerFuda)}></td>
                                    <td className={classNames(partner, partnerHongkon)}></td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{ fontSize: '20px', letterSpacing: '1px', color: '#A1A1A1', textAlign: 'center', padding: '1em' }}>
                            期待您的加入...
                        </div>
                    </div>
                </section>
                <section id="d" style={{ padding: '120px 16%' }} className={optionContainer}>
                    <div className={aboutus}>
                        <h4>新闻动态</h4>
                        <div className={line}></div>
                        <p className={target}>以行业需求为目标、以用户体验为宗旨、以科技发展为驱动</p>
                        <div className={news}>
                            <div>
                                <div className={newImg}><img src="/about/news-1.png" alt="" /></div>
                                <div className={newRight}>
                                    <h3><a target={"_blank"} href="https://baijiahao.baidu.com/s?id=1729988999350521191&wfr=spider&for=pc">好消息!郑州地铁12号线最后1台盾构机始发,即将实现“洞通”</a> </h3>
                                    <p>2022年4月13日 据了解，郑州地铁12号线一期工程全长17千米，全部为地下线；设车站12座，全部为地下站；列车采用6节编组B型列车。“开拓二号”盾构机主要承担郑州地铁12号线西周站至黄河南路站区间右线的施工任务。该盾构区间右线长约1296米，以西周站为起点，向西北方向沿黄河南路敷设接入黄河南路站。</p>
                                </div>
                            </div>
                            <div>
                                <div className={newImg}><img src="/about/news-2.png" alt="" /></div>
                                <div className={newRight}>
                                    <h3><a target={"_blank"} href="http://k.sina.com.cn/article_2818784432_a8033cb000101l4ae.html">我国地铁建设领域一项新技术、新工艺在郑州地铁12号线中运用</a></h3>
                                    <p>近日，在郑州轨道12号线08工区施工现场，伴随着马达声的响起，河南首座机械法联络通道顶管机1062号在已施工完成的郑州轨道12号线一期工程龙子湖西站～龙子湖站区间隧道内顺利始发，标志着我国地铁建设施工领域中又一项新技术、新工艺在中原大地顺利启航。据悉，采用机械法进行联络通道施工，在郑州地铁尚属首次，也是在河南省内的首次应用。</p>
                                </div>
                            </div>
                            <div>
                                <div className={newLeft}>
                                    <h3><a target={"_blank"} href="http://www.stdaily.com/index/kejixinwen/202206/00154624762c4b419eda04460ea27f59.shtml">BIM+AI，打造施工项目智慧建造“超级大脑”</a> </h3>
                                    <p>作为人工智能在建筑施工领域应用的具体体现，该平台能够按照项目现场业务管理的逻辑，打通数据之间的壁垒，围绕施工过程进度管理，建立互联协同，智能生产，科学管理的施工项目信息化生态圈，有效解决了传统工地缺乏系统之间互相集成、硬件之间“数据孤岛”、管理平台存在“重展示、轻交互”等现象，全力打造施工项目智慧建造“超级大脑”，有效地实现工地的数字化、精细化、智慧化生产和管理，提升了工程项目建设的技术和管理水平。</p>
                                </div>
                                <div className={newImg}><img src="/about/news-3.png" alt="" /></div>
                            </div>
                            <div>
                                <div className={newLeft}>
                                    <h3><a target={"_blank"} href="https://it.sohu.com/a/543447223_682294">城市轨道交通行业BIM标准汇总</a></h3>
                                    <p>在数字化、工业化、智能化发展的大背景下，BIM技术应用也在持续性发展，国家和各地方在各行业陆续发表了各式各样的标准，目前BIM放大镜对适用于城市轨道交通行业的BIM标准进行的梳理汇总，便于小伙伴在业务推广、快速学习、熟练应用中能够快速知晓国家和地方的BIM应用状态。</p>
                                </div>
                                <div className={newImg}><img src="/about/news-4.png" alt="" /></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* <div className={main} style={{ display: active === 1 ? "block" : 'none' }}>

            </div> */}
        </MainFrame>
    )
}