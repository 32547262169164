// extracted by mini-css-extract-plugin
export var aboutus = "about-module--aboutus--SDZqp";
export var bottom = "about-module--bottom--PcuZm";
export var certificate = "about-module--certificate--+93Lp";
export var certificateMain = "about-module--certificateMain--9EtfQ";
export var line = "about-module--line--Rciwm";
export var main = "about-module--main--r4bts";
export var newImg = "about-module--newImg--JEzQ9";
export var newLeft = "about-module--newLeft--iKf5x";
export var newRight = "about-module--newRight--Tc4dK";
export var news = "about-module--news--KltB0";
export var people = "about-module--people--eCrfx";
export var peopleImg = "about-module--peopleImg--cD03J";
export var peopleName = "about-module--peopleName--Phpkl";
export var peoplePy = "about-module--peoplePy--KFw93";
export var peopleRight = "about-module--peopleRight--bBF4i";
export var target = "about-module--target--jj6nb";
export var text = "about-module--text--UeL-L";
export var textLeft = "about-module--textLeft--MDcAN";
export var textRight = "about-module--textRight--GLcai";
export var top = "about-module--top--eGpNB";
export var why = "about-module--why--hnBPQ";
export var zyq = "about-module--zyq--G42ls";
export var zyqName = "about-module--zyqName--v4a3l";
export var zyqRight = "about-module--zyqRight--fXmjB";